// +-------------------------------------------------------------
// | Created by WebStorm.
// +-------------------------------------------------------------
// | User: msmile
// +-------------------------------------------------------------
// | Date: 2022/6/7
// +-------------------------------------------------------------
// |Time: 14:30
// +-------------------------------------------------------------
import request from "@/utils/request";
//代金券列表
export function bindList(data){
    return request({
        method: 'post',
        url: '/user-balance/bind-list',
        data,
    })
}
